.page-wrapper {
  background-color: #111;
  height: 100vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.back-home {
  color: #fafafa;
  margin-top: 1em;
}
h1 {
  font-size: 10em;
}
