:root {
  --THEME_COLOR: #111;
  --FONT_FAMILY: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  --NAVBAR_COLOR_SOLID: #111;
  --NAVBAR_FONT_COLOR: #fff;
  --NAVBAR_COLOR_GRAD: #111;
  --FONT_COLOR: #fff;
  --FEATURED_INFO_BOX_FONT_COLOR: #fff;
  --FEATURED_INFO_BOX_PLAY_BG_COLOR: #fff;
  --FEATURED_INFO_BOX_PLAY_COLOR: #111;
  --FEATURED_INFO_BOX_INFO_BG_COLOR: gray;
  --FEATURED_INFO_BOX_INFO_COLOR: #fff;
  --FEATURED_INFO_BOX_BG_COLOR: rgba(17, 17, 17, 0.5);
  --FEATURED_INFO_BOX_BORDER: 1px solid white;
  --HOVER_MINI_PLAYER_BOX_SHADOW: 0px 0px 15px 0px rgba(255, 255, 255, 0.07);
  --HOVER_MINI_PLAYER_BOX_ICON_COLOR: #fff;
  --HOVER_MINI_PLAYER_BOX_ICON_BORDER: 2px solid white;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: var(--FONT_FAMILY);
}

a {
  color: inherit;
  text-decoration: none;
}
