.listItem {
  width: 225px;
  height: 120px;
  background-color: var(--THEME_COLOR);
  margin-right: 5px;
  overflow: hidden;
  cursor: pointer;
  color: white;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  video {
    width: 100%;
    height: 140px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    width: 325px;
    height: 300px;
    position: absolute;
    top: -150px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.07);
    box-shadow: var(--HOVER_MINI_PLAYER_BOX_SHADOW);
    border-radius: 5px;

    img {
      height: 140px;
    }

    .itemInfo {
      display: flex;
      flex-direction: column;
      padding: 5px;

      .icons {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        color: var(--HOVER_MINI_PLAYER_BOX_ICON_COLOR);

        .icon {
          height: 40px;
          width: 40px;
          border: var(--HOVER_MINI_PLAYER_BOX_ICON_BORDER);
          padding: 5px;
          border-radius: 50%;
          margin-right: 10px;
          font-size: 16px;
        }
      }
      .itemInfoTop {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 600;
        color: gray;

        .limit {
          border: 1px solid gray;
          padding: 1px 3px;
          margin: 0 10px;
        }
      }

      .desc {
        font-size: 13px;
        margin-bottom: 10px;
      }

      .genre {
        font-size: 14px;
        color: lightgray;
      }
    }
  }
}
.video-container {
  box-shadow: 20px 20px 20px #0000003b;
}
.video-player {
  position: absolute;
  top: 0;
  left: 0;
}
.image-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.searchItem {
  width: 225px;
  height: 120px;
  background-color: var(--THEME_COLOR);
  margin-right: 5px;
  overflow: hidden;
  cursor: pointer;
  color: white;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  video {
    width: 100%;
    height: 140px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
}
