.watch {
  width: 100vw;
  height: 100vh;

  .back {
    display: flex;
    align-items: center;
    position: absolute;
    top: 12px;
    left: 10px;
    color: white;
    cursor: pointer;
    z-index: 2;

    a {
      font-size: 20px;
      font-weight: bold;
      text-decoration: none;
      margin-top: 25px;
    }
  }

  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
